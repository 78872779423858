export default defineNuxtRouteMiddleware((to) => {
  const token = localStorage.getItem('token')
  const routeAfterAuth = useRouteAfterAuth()

  if (token) {
    return
  }

  routeAfterAuth.value = to.fullPath
  return navigateTo('/auth/login', { replace: true })
})
